import { DeviceRight } from "./device-right.model";
import { Entity } from "./entity.model";
import { Role } from "./role.model";
import { User } from "./user.model";

export class UserGroup extends Entity {
  name: string;
  description: string;
  createdAt: string;
  active: boolean;
  roles: Role[];
  users: User[];
  updatedAt: string;
  updatedBy: string;
  createdBy: string;
  canDelete: boolean;
  canEdit: boolean;
  company: {
    name: string;
    id: number;
  };
  deviceRights: DeviceRight[];
  canUnassign: boolean;

  constructor({
    id,
    name,
    description,
    createdAt,
    active,
    roles,
    users,
    deviceRights,
    updatedAt,
    updatedBy,
    createdBy,
    canDelete,
    canEdit,
    company,
    canUnassign,
  }: UserGroup) {
    super(id);
    this.name = name;
    this.description = description;
    this.createdAt = createdAt;
    this.active = active;
    this.roles = roles;
    this.users = users;
    this.deviceRights = deviceRights || [];
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.createdBy = createdBy;
    this.canDelete = canDelete;
    this.canEdit = canEdit;
    this.canUnassign = canUnassign;
    this.company = {
      name: company?.name,
      id: company?.id,
    };
  }
}
